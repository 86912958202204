import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from "styles/structure";
import Content from 'components/_ui/Content';
import SectionHeading from "components/_ui/SectionHeading";
import NewsItem from 'components/_page/newsroom/NewsItem';
import ArrowLink from "components/_ui/ArrowLink";
import LoadMore from 'components/_ui/LoadMore';

const NewsListContainer = styled.section`
  background-color: #000;
  color: #fff;
  padding-top: 4.8rem;
  
  &.NewsList--home {
    margin-top: -2rem;
    padding: 8rem 0 5.6rem;
  }

  header {
    & + * {
      margin-top: 8rem;
    }
    
    div {
      margin-top: 1.6rem;
    }
  }
  
  p {
    max-width: 74rem;
  }
  
  ul {
    display: block;
    position: relative;
    width: 100%;
  }
  
  li {
    position: relative;
    
    & + * {
      margin-top: 1.6rem;
    }
    
    div {
      position: relative;
      z-index: 10;
    }
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    padding-bottom: 8rem;
    
    &.NewsList--home {
      margin-top: 12rem;
    }
    
    header {
      & + * {
        margin-top: 6.4rem;
      }
    }
    
    ul {
      &:hover {
        li {
          opacity: 0.5;
        }
      }
    }
    
    li {
      transition: opacity 0.2s ease-in-out;
      
      & + * {
        margin-top: 6.4rem;
      } 
      
      &:hover {
        opacity: 1 !important;
      }
    }
  }
`;

const NewsListTrapdoor = styled.div`
  margin-top: 4rem;
  text-align: center;
  
  > * {
    margin: 0 auto;
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    margin-top: 10.4rem;
  }
`;

class NewsList extends Component {
  render() {
    const { heading, content, news, hasMore, onLoadMore, variant } = this.props;

    return (
      <NewsListContainer className={variant ? `NewsList--${variant}` : ''}>
        <Inner>
          <Wrap>
            {(heading || content) && (
              <header>
                {heading && <SectionHeading>{heading}</SectionHeading>}
                {content && <Content content={content}/>}
              </header>
            )}

            {(news && news.length > 0) && (
              <ul>
                {news.map((post, i) => {
                  if (post.post) {
                    return (
                      <li key={i}>
                        <NewsItem
                          key={i}
                          destination={`/post/${post.post._meta.uid}`}
                          type={post.post.type}
                          title={post.post.title}
                          image={post.post.feature_image}
                        />
                      </li>
                    );
                  } else if (post.node) {
                    return (
                      <li key={i}>
                        <NewsItem
                          key={i}
                          destination={`/post/${post.node._meta.uid}`}
                          type={post.node.type}
                          title={post.node.title}
                          image={post.node.feature_image}
                        />
                      </li>
                    );
                  }

                  return null;
                })}
              </ul>
            )}

            <NewsListTrapdoor>
              {variant === 'home' && <ArrowLink text="View All News" to="/newsroom"/>}

              {variant === 'newsroom' && hasMore && (
                <LoadMore
                  text='View More News'
                  onClick={() => {onLoadMore()}}
                />
              )}
            </NewsListTrapdoor>
          </Wrap>
        </Inner>
      </NewsListContainer>
    );
  }
}

export default NewsList;
