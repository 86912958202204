import React, { Component } from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import ProgressBar from "components/ProgressBar";
import Slide from "react-reveal/Slide";
import { InView } from "react-intersection-observer";
import WorkSlideContent from "./_page/homepage/FeaturedWorkContent";
import FeaturedWorkHeader from "../components/_page/homepage/FeaturedWorkHeader";

const SlideShowWrapper = styled.div``;

const ProgressBarWrapper = styled.div`
  position: absolute;
  top: calc(50% + 12rem);
`;

const SlideshowContainer = styled(InView)`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
  }
`;

const Slider = styled.div`
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation-delay: 0ms !important;
    animation-duration: 400ms !important;
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {}
`;

const WorkSlider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  min-width: 120rem;
  min-height: 34rem;
  padding-right: 2rem;
  
  & + * {
    margin-top: 3.2rem;
  }
`;

const FeaturedWorkSlide = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  
  &.is-active {
    position: relative;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.4s ease-in-out;
    
    q {
      transition: transform 0.66s ease-out;
      transform: translateY(0);
    }
  }
`;

const SlideshowSlider = styled.div`
  width: 100%;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-self: center;
    & + * {
      margin-left: 5.6vw;
    }
  }
  @media (min-width: ${dimensions.desktopUp}px) {
    .Slideshow--events {
      width: 56.4rem;
      flex: 0 0 56.4rem;
    }
  }
`;

class Slideshow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: -1,
      timer: null,
      isPlaying: false,
    };
  }

  advanceSlide() {
    const newSlide =
      this.state.activeSlide < this.props.slides.length - 1
        ? this.state.activeSlide + 1
        : 0;

    this.setState({
      activeSlide: newSlide,
    });
  }

  goToSlide(slide) {
    clearInterval(this.state.timer);

    this.setState({
      activeSlide: slide,
      isPlaying: false,
    });
  }

  componentDidMount() {
    if (this.props.slides.length > 1) {
      const timer = setInterval(() => {
        this.advanceSlide();
      }, this.props.duration);

      this.advanceSlide();

      this.setState({
        timer: timer,
        isPlaying: true,
      });
    } else {
      this.advanceSlide();
    }
  }

  onInview(inView) {
    if (!inView && this.state.isPlaying) {
      clearInterval(this.state.timer);

      this.setState({
        isPlaying: false,
      });
    } else if (inView && !this.state.isPlaying) {
      const timer = setInterval(() => {
        this.advanceSlide();
      }, this.props.duration);

      this.setState({
        timer: timer,
        isPlaying: true,
      });

      this.advanceSlide();
    }
  }

  render() {
    const active = this.state.activeSlide;
    const slides = this.props.slides;
    const self = this;

    return (
      <SlideShowWrapper>
        {slides.length > 1 && (
          <ProgressBarWrapper>
            <ProgressBar
              slides={slides}
              action={this.goToSlide.bind(this)}
              activeSlide={active}
              duration={this.props.duration}
              isPlaying={this.state.isPlaying}
            />
          </ProgressBarWrapper>
        )}

        <SlideshowContainer
          as="div"
          onChange={inView => {
            self.onInview(inView);
          }}
          className={`${!self.state.isPlaying ? "is-stopped" : ""} ${self.props
            .variant && `Slideshow--${self.props.variant}`}`}
        >
          <SlideshowSlider>
            {slides.length && (
              <Slider>
                {slides.map((slide, i) => (
                  <Slide
                    key={i}
                    right
                    when={i === active}
                    delay={0}
                    opposite
                    collapse
                  ></Slide>
                ))}
              </Slider>
            )}

            <WorkSlider>
              <FeaturedWorkHeader
                variant="work--desktop"
                heading={this.props.heading}
              ></FeaturedWorkHeader>

              {slides.map((slide, i) => (
                <FeaturedWorkSlide
                  key={i}
                  className={i === active && "is-active"}
                >
                  <WorkSlideContent
                    key={i}
                    caseStudy={slide.case_study}
                    insight={slide.insight}
                    quote={slide.quote}
                  />
                </FeaturedWorkSlide>
              ))}
            </WorkSlider>
          </SlideshowSlider>
        </SlideshowContainer>
      </SlideShowWrapper>
    );
  }
}

export default Slideshow;
