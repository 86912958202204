import React, { Component } from "react";
import styled from "@emotion/styled";
import ProgressCircle from "../vectors/progress-circle.svg";
import { keyframes } from "@emotion/core";
import colors from "styles/colors";
import dimensions from "styles/dimensions";

const draw = keyframes`
0% {
  stroke-dashoffset: 280;

}
50% {
  stroke-dashoffset: 75;
}
`;

const ProgressBarContainer = styled.button`
  position: relative;
  right: 6rem;
  transform: translateX(-5rem);
  transition: 1s;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
  transform: translateX(0);
    transition: all 1s;
  }
`;

const CircleContainer = styled.div`
  display: none;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
      margin: -1.6rem 0;
    }

    .circle {
      cursor: pointer;
      fill: transparent;
      stroke-width: 2;
      overflow: visible;
      stroke-dasharray: 283;
      transform: rotate(-90deg);
      stroke-linecap: round;
      transform-origin: 50% 50%;
      stroke-dashoffset: 75;
      animation: ${props => props.active && draw} 17s linear both;
      animation: ${props => !props.isPlaying && "none"};
      stroke: ${props => (props.active ? colors.red600 : "white")};
    }

    .outline {
      stroke: ${props => (props.active ? colors.grey300 : "white")};
      display: ${props => !props.isPlaying && "none"};
    }
  }
`;

class ProgressBar extends Component {
  handleClick = slide => {
    this.props.action(slide);
  };

  render() {
    return (
      <ProgressBarContainer ref={node => (this.node = node)}>
        {this.props.slides.map((slide, i) => (
          <CircleContainer
            key={i}
            active={this.props.activeSlide === i}
            isPlaying={this.props.isPlaying}
            onClick={() => {
              this.handleClick(i);
            }}
          >
            <ProgressCircle />
          </CircleContainer>
        ))}
      </ProgressBarContainer>
    );
  }
}

export default ProgressBar;
