import React from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import { Inner } from "styles/structure";
import Slideshow from "components/Slideshow";
import FeaturedWorkContent from "../components/_page/homepage/FeaturedWorkContent";
import FeaturedWorkHeader from "../components/_page/homepage/FeaturedWorkHeader";

const FeaturedWorkContainer = styled.div`
  overflow: hidden;

  & + * {
    margin-top: 4.8rem;
  }

  header {
    text-align: center;
    margin-bottom: 4rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      margin-bottom: 5.6rem;
    }

    & + * {
      margin-top: 9.6rem;
    }
  }
`;

const FeaturedWorkInner = styled(Inner)`
  padding: 0;
`;

const FeaturedWorkWrapper = styled.div`
  position: relative;
  background-color: ${colors.orange100};
  padding-top: 4rem;
  display: ${props => (props.isForMobile ? "block" : "none")};

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 7.2rem 2.4rem;
    display: ${props => (props.isForMobile ? "none" : "block")};
  }
`;

const FeaturedWork = ({ slides, variant, delay, heading }) => {
  return (
    <FeaturedWorkContainer>
      <FeaturedWorkInner>
        <FeaturedWorkWrapper isForMobile={false}>
          <Slideshow
            heading={heading}
            duration={delay}
            slides={slides}
            variant={variant}
          />
        </FeaturedWorkWrapper>

        <FeaturedWorkWrapper isForMobile={true}>
          <FeaturedWorkHeader
            variant="mobile"
            heading={heading}
          ></FeaturedWorkHeader>

          {slides.length && (
            <FeaturedWorkContent
              caseStudy={slides[0].case_study}
              insight={slides[0].insight}
              quote={slides[0].quote}
            />
          )}
        </FeaturedWorkWrapper>
      </FeaturedWorkInner>
    </FeaturedWorkContainer>
  );
};

export default FeaturedWork;
