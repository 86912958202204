import React from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import SignPost from "../../_ui/Signpost";
import ArrowLink from "../../_ui/ArrowLink";

const HeadingContainer = styled.div`
  margin: 12rem 0 6rem 2.8rem;

  h2 {
    width: 72%;
    margin-bottom: 4rem;
  }

  a {
    margin-right: 6rem;
    margin-bottom: 0.8rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin: 12rem 0 6rem 4rem;
    h2 {
      margin-bottom: 2rem;
    }
    a {
      margin-right: 14.6rem;
      margin-bottom: 0.8rem;
    }
  }
`;

const FeaturedWorkHeader = props => {
  return (
    <>
      <HeadingContainer className={`is-${props.variant}`}>
        <SignPost variant='work' size="medium">Highlights</SignPost>
        <h2>{props.heading || ""}</h2>
        <ArrowLink text="See Our Work" to="/work" />
        <ArrowLink text="Read Our Insights" to="/insights" />
      </HeadingContainer>
    </>
  );
};
export default FeaturedWorkHeader;
