import React, { Component } from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Quote from "../../Quote";
import { Grid } from "styles/structure";
import Insight from "../../_card/Insight";
import {slideInLeft, slideInBottom, slideInTop} from 'styles/animations'
import CaseStudy from "../../_card/CaseStudy";

const WorkContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    height: auto;
    min-height: 58.4rem;
  }
`;

const WorkGrid = styled(Grid)`
  width: 100%;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 4rem;
    max-width: 122rem;
    min-height: 86rem;
  }
`;

const CaseStudyContainer = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1;
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
animation: ${props => props.slideStyle && slideInTop} 0.6s ease-in;
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }
`;

const InsightContainer = styled.div`
  grid-row: 3;
  grid-column: 1;
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    animation: ${props => props.slideStyle && slideInLeft} 0.6s ease-in;
    grid-row: 2;
    grid-column: 1;
  }
`;
const QuoteContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  grid-row: 4;
  grid-column: 1;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    animation: ${props => props.slideStyle && slideInBottom} 0.6s ease-in;
    grid-row: 2;
    grid-column: 2;
  }
`;

class FeaturedWorkContent extends Component {
  state = { slideStyle: false };

  componentDidUpdate() {
    this.updateAndNotify();
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    return { notifyRequired: prevProps.text !== this.props.text };
  }

  updateAndNotify = () => {
    if (this.updateTimer) return;
    this.setState({ slideStyle: true });
    this.updateTimer = setTimeout(() => {
      this.setState({ slideStyle: false });
      this.updateTimer = null;
    }, 2000);
  };

  render() {
    const { caseStudy, insight, quote } = this.props;
    return (
      <WorkContainer>
        <WorkGrid>
          <CaseStudyContainer slideStyle={this.state.slideStyle}>
            <CaseStudy
              area={caseStudy.area}
              name={caseStudy.client_name}
              image={caseStudy.feature_image}
              tagline={caseStudy.tagline}
              uid={caseStudy._meta.uid}
              variant="work"
              align="left"
              center={true}
            />
          </CaseStudyContainer>

          {insight && (
            <InsightContainer slideStyle={this.state.slideStyle}>
              <Insight
                link={`/insight/${insight._meta.uid}`}
                image={insight.feature_image}
                title={insight.title}
                variant="work"
                category={insight.category}
              />
            </InsightContainer>
          )}

          {quote && (
            <QuoteContainer slideStyle={this.state.slideStyle}>
              <Quote quote={quote} variant="work" />
            </QuoteContainer>
          )}
        </WorkGrid>
      </WorkContainer>
    );
  }
}

export default FeaturedWorkContent;
