import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from "styles/structure";
import { Link } from "gatsby";
import Caret from "vectors/caret-right.svg";
import ArrowLink from "components/_ui/ArrowLink";
import Fade from "react-reveal/Fade";

const SpecialtiesContainer = styled.section`
  display: block;
  position: relative;
  width: 100%;
  margin-top: 7.2rem;
 
  h1 {
    position: relative;
    padding-top: 2.4rem;
    width: 100%;
    max-width: 118.4rem;
    z-index: 1;
    
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: calc(-50vw + 3.2rem);
      width: 50vw;
      height: 0.3rem;
      background-color: ${colors.red600};
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 14.4rem;
    min-height: 60rem;
    height: 72vh;
   
    h1 {
      padding-top: 0;
      padding-left: 11.2rem;
      top: 6.4rem;
    
      &::before {
        position: absolute;
        left: initial;
        right: calc(100% - 8.8rem);
        top: 3.2rem;
      }
    }
  }
`;

const SpecialtiesContent = styled(Inner)` 
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    position: relative;
    top: 16%;
    transition: opacity 0.16s ease-in 0.22s;
    
    &.is-faded {
      opacity: 0.05;
      transition: opacity 0.16s ease-in;
    }
  }
`;

const SpecialtiesFloat = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;

  a {
    position: absolute;
    
    &:nth-of-type(1) {
      top: 0;
      right: 2.4rem;
    }
    
    &:nth-of-type(2) {
      top: 40%;
      left: 2.4rem;
    }
    
    &:nth-of-type(3) {
      bottom: 0;
      right: 2.4rem;
    }
    
    &:hover {
      h2 {
        color: ${colors.red600};
        -webkit-text-stroke: initial;
        text-stroke: initial;
      }
      
      // arrow
      > div {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.12s ease-in-out, visibility 0s 0s;
        
        div {
          width: 8rem;
        }
      }
    }
  }
  
  h2 {
    display: block;
    color: transparent;
    font-size: 8.8vw;
    font-weight: 600;
    -webkit-text-stroke: 0.2rem rgba(0,0,0, 0.33);
    text-stroke: 0.2rem rgba(0,0,0, 0.33);
    transition: text-stroke 0.16s ease-in-out, color 0.16s ease-in-out;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: block;
  }
`;

const SpecialtiesArrow = styled.div`
  display: inline-block;
  position: absolute;
  bottom: -2.4rem;
  right: 6.4rem;
  padding-right: 7.2rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.12s ease-in-out, visibility 0s 0.12s;
  
  span {
    color: ${colors.red600};
    font-size: 1.6rem;
    font-weight: 500;
  }
  
  div {
    position: absolute;
    left: calc(100% - 4.8rem);
    // visual centering
    top: 42%;
    width: 4.8rem;
    height: 0.2rem;
    background-color: ${colors.red600};
    transition: width 0.12s ease-in-out;
  }
  
  svg {
    display: block;
    position: absolute;
    right: -0.2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9rem;
    height: 1.7rem;
    fill: ${colors.red600};
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {  
    span {
      font-size: 1.8rem;
    }
    
    div {
      // visual centering
      top: 44%;
    }
  }
`;

const SpecialtiesList = styled(Inner)`
  margin-top: 6.4rem;
  
  div {
    & + * {
      margin-top: 6.4rem;
    }
  }
  
  h2 {
    font-size: 6.4rem;
    line-height: 1.11;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: none;
  }
`;

class HomepageSpecialties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFaded: false,
    }
  }

  onMouseEnter = () => {
    this.setState({ isFaded: true });
  };

  onMouseLeave = () => {
    this.setState({ isFaded: false });
  };

  render() {
    const self = this;

    return (
      <SpecialtiesContainer>
        <SpecialtiesFloat>
          <Link
            to="/specialty/public-relations"
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <Fade bottom distance="4rem">
              <h2>Public Relations</h2>

              <SpecialtiesArrow>
                <span>Learn About Our Specialties</span>

                <div>
                  <Caret/>
                </div>
              </SpecialtiesArrow>
            </Fade>
          </Link>


          <Link
            to="/specialty/public-affairs"
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <Fade bottom distance="4rem">
              <h2>Public Affairs</h2>

              <SpecialtiesArrow>
                <span>Learn About Our Specialties</span>

                <div>
                  <Caret/>
                </div>
              </SpecialtiesArrow>
            </Fade>
          </Link>

          <Link
            to="/specialty/creative-studio"
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <Fade bottom distance="4rem">
              <h2>Creative Studio</h2>

              <SpecialtiesArrow>
                <span>Learn About Our Specialties</span>

                <div>
                  <Caret/>
                </div>
              </SpecialtiesArrow>
            </Fade>
          </Link>
        </SpecialtiesFloat>

        <SpecialtiesContent className={self.state.isFaded ? "is-faded" : null}>
          <h1>{this.props.heading}</h1>
        </SpecialtiesContent>

        <SpecialtiesList>
          <div>
            <h2>Public Relations</h2>
            <ArrowLink to="/specialty/public-relations" text="Learn About Our Specialties" />
          </div>

          <div>
            <h2>Public Affairs</h2>
            <ArrowLink to="/specialty/public-affairs" text="Learn About Our Specialties" />
          </div>

          <div>
            <h2>Creative Studio</h2>
            <ArrowLink to="/specialty/creative-studio" text="Learn About Our Specialties" />
          </div>
        </SpecialtiesList>
      </SpecialtiesContainer>
    );
  }
}

export default HomepageSpecialties;
