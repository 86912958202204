import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HomepageHeader from 'components/_page/homepage/Header';
import NewsList from 'components/_grid/News';
import HomepageIndustries from 'components/_page/homepage/Industries';
import HomepageInsights from 'components/_page/homepage/Insights';
import HomepageTeam from 'components/_page/homepage/Team';
import HomepageSpecialties from 'components/_page/homepage/Specialties'
import FeaturedWork from 'components/FeaturedWork';

const RenderBody = ({ home, site }) => (
  <>
    <HomepageHeader tagline={home.tagline} />

    {/* Who we are */}
    <HomepageSpecialties
      heading={home.who_we_are_heading}
    />

    {home.featured_work && (
      <FeaturedWork
        delay={6000}
        featuredWork={home.featured_work}
        slides={home.featured_work}
        heading={home.work_heading}
        variant='work'
      />
    )}

    {/* News */}
    <NewsList
      heading={home.news_heading}
      content={home.news_content}
      news={home.featured_news}
      variant='home'
    />

    {/* Industries */}
    <HomepageIndustries
      heading={home.industries_heading}
      industries={site.featured_industries}
      clients_heading={home.clients_heading}
      clients={home.clients_list}
    />

    {/* Insights */}
    <HomepageInsights
      heading={home.insights_heading}
      content={home.insights_content}
      insights={home.featured_insights.slice(0,3)}
    />

    {/* Team */}
    <HomepageTeam
      heading={home.team_heading}
      image={home.team_image.url}
    />
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const home = data.prismic.allHomepages.edges[0].node;
  const site = data.prismic.allSite_settingss.edges[0].node;

  if (!home) return null;

  return (
    <Layout headerStyle='homepage'>
      <SEO
        title={home.social_title ? home.social_title : null}
        description={home.social_description ? home.social_description : null}
        image={home.social_image ? home.social_image.url : null}
        home={true}
      />

      <RenderBody home={home} site={site} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            tagline
            who_we_are_heading
            work_heading
            featured_work {
              case_study {
                ... on PRISMIC_Case_study {
                  client_name
                  tagline
                  area {
                    ... on PRISMIC_Area {
                      name
                    }
                  }
                  feature_image
                  _meta {
                    uid
                  }
                }
              }
              insight {
                ... on PRISMIC_Insight {
                  title
                  feature_image
                  category {
                    ... on PRISMIC_Category {
                      name
                    }
                  }
                  _meta {
                    uid
                  }
                }
              }
              quote {
                ... on PRISMIC_Quote {
                  content
                  name
                  title
                  _meta {
                    uid
                  }
                }
              }
            }
            news_heading
            news_content
            featured_news {
              post {
                ... on PRISMIC_Post {
                  feature_image
                  _meta {
                    uid
                  }
                  title
                  type {
                    ... on PRISMIC_Type {
                      name
                    }
                  }
                }   
              }
            }
            industries_heading
            clients_heading
            clients_list {
              logo
              name
              case_study {
                ... on PRISMIC_Case_study {
                  _meta {
                    uid
                  }
                  logo
                  client_name
                }
              }
            }
            insights_heading
            insights_content
            featured_insights {
              insight {
                ... on PRISMIC_Insight {
                  feature_image
                  _meta {
                    uid
                  }
                  title
                  category {
                    ... on PRISMIC_Category {
                      name
                    }
                  }
                }
              }
            }
            team_heading
            team_image
            social_title
            social_description
            social_image
          }
        }
      }
      allSite_settingss {
        edges {
          node {
            featured_industries {
              area {
                ... on PRISMIC_Area {
                  name
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
