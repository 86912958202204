import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Signpost from "components/_ui/Signpost";
import logo from "images/logo_marino--temp.png";
import Fade from "react-reveal/Fade";

const HeaderContainer = styled.header`
  position: relative;
  width: 100%;
  
  > div {
    height: 80vh;
    padding-top: 8.8rem;
  }
  
  h2 {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 120rem;
    padding-left: 14.4vw;
  }
  
  img {
    position: absolute;
    bottom: 0.8rem;
    left: 0;
    width: 90vw;
    height: auto;
    pointer-events: none;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    > div {
      padding-top: 12rem;
    }
    
    h2 {
      padding-left: 38.46vw;
    }
    
    img {
      bottom: 2.4rem;
      left: 0;
      width: 66vw;
    }
  }
  
  @media(min-width: ${dimensions.desktopLargeUp}px) {
    h2 {
      padding-left: 69.2rem;
    }
    
    img {
      width: 129.6rem;
      left: -1.6rem;
    }
  }
`;

class HomepageHeader extends Component {
  render() {
    return (
      <HeaderContainer>
        <Inner>
          <Fade bottom distance="6.4rem">
          <h2>{this.props.tagline}</h2>
          </Fade>

          <Fade bottom distance="8rem" duration={1200}>
          <img src={logo} alt="Marino logo" />
          </Fade>

          <Signpost size="large">Learn more</Signpost>
        </Inner>
      </HeaderContainer>
    );
  }
}

export default HomepageHeader;
