import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import Content from 'components/_ui/Content';
import SectionHeading from "components/_ui/SectionHeading";
import InsightsGrid from "components/_grid/Insights";
import InsightCard from "components/_card/Insight";
import ArrowLink from "components/_ui/ArrowLink";

const InsightsContainer = styled.section`
  background-color: #000;
  color: #fff;
  margin-top: 5.6rem;
  padding: 8rem 0 5.6rem;
  
  header {
    & + * {
      margin-top: 4.8rem;
    }
    
    div {
      margin-top: 1.6rem;
    }
  }
  
  p {
    max-width: 74rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 14.4rem;
    padding: 9.6rem 0 6.4rem;
    
    header {
      & + * {
        margin-top: 6.4rem;
      }
    }
  }
`;

const InsightsTrapdoor = styled.div`
  margin-top: 4rem;
  text-align: center;
  
  > * {
    margin: 0 auto;
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    margin-top: 6.4rem;
  }
`;

class HomepageInsights extends Component {
  render() {
    const {heading, content, insights} = this.props;
    return (
      <InsightsContainer>
        <Inner>
          <header>
            <Wrap>
              {heading && <SectionHeading>{heading}</SectionHeading>}

              {content && (
                <Content content={content}/>
              )}
            </Wrap>
          </header>

          {(insights && insights.length > 0) && (
            <InsightsGrid>
              {insights.map(({insight}, i) => (
                <li key={i}>
                  <InsightCard
                    destination={`/insight/${insight._meta.uid}`}
                    image={insight.feature_image}
                    title={insight.title}
                    category={insight.category}
                  />
                </li>
              ))}
            </InsightsGrid>
          )}

          <InsightsTrapdoor>
            <ArrowLink text="Read Our Insights" to="/insights"/>
          </InsightsTrapdoor>
        </Inner>
      </InsightsContainer>
    );
  }
}

export default HomepageInsights;
