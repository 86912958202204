import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Link } from "gatsby";
import { Rubric } from "styles/typography";
import Fade from "react-reveal/Fade";

const NewsItemContainer = styled(Link)`
  color: #fff;
  
  h3 {
    margin-top: 0.8rem;
    text-shadow: 0.2rem 0.2rem 0.8rem rgba(0,0,0,0.12);
  }
  
  img {
    position: relative;
    top: -4rem;
    left: calc(36vw - 4.8rem);
    width: 64vw;
    height: auto;
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    &:hover {
      img {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: opacity 0.12s ease-in-out, visibility 0s 0s, transform 0.2s ease-in;
      }
    }
    
    img {
      position: absolute;
      right: 0;
      left: auto;
      top: -8rem;
      max-width: 49.6rem;
      margin-top: 2.4rem;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translateY(0.8rem);
      transition: opacity 0.12s ease-in-out, visibility 0s 0.12s transform 0.2s ease-out;
    }
  }
`;

class NewsItem extends Component {
  render() {
    const { destination, type, title, image } = this.props;

    return (
      <Fade bottom distance="2rem">
        <NewsItemContainer to={destination}>
          <div>
            {type && <Rubric>{type.name}</Rubric>}
            {title && <h3>{title[0].text}</h3>}
          </div>

          {image && (
            <img
              src={image.url}
              alt={image.alt}
            />
          )}
        </NewsItemContainer>
      </Fade>
    );
  }
}

export default NewsItem;
