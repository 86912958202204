import React, { Component } from 'react';
import { Link } from "gatsby";
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import Signpost from 'components/_ui/Signpost';
import ArrowLink from 'components/_ui/ArrowLink';
import Fade from "react-reveal/Fade";

const IndustriesContainer = styled.section`
  position: relative;
  margin-top: 12rem;
  
  header {
    & + * {
      margin-top: 6.4rem;
    }
  }
  
  h2 {
    padding-right: 4.8rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 16rem;
    
    h2 {
      padding-right: 8rem;
    }
  }
`;

const IndustriesTrapdoor = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  > * {
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: block;
    
    > * {
      & + * {
        margin-top: 0;
        margin-left: 11.11vw;
      }
    } 
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    > * + * {
      margin-left: 16rem;
    }
  }
`;

const IndustriesContent = styled.div`
  background-color: ${colors.grey100};
  padding: 4rem 2.4rem;
  
  & + * {
    margin-top: 4rem;
  }
  
  ul {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
  }
  
  li {
    font-size: 1.4rem;
    flex: 0 0 50%;
    padding-right: 1.6rem;
    
    a {
      color: inherit;
      transition: color 0.08s ease-in-out;
    }
    
    &:nth-of-type(n+3) {
      margin-top: 3.2rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    padding: 8.8rem 9.4vw;
    
    & + * {
      margin-top: 6.4rem;
    }
  
    li {
      flex-basis: 33.33334%;
      
      &:nth-of-type(n+3) {
        margin-top: 0;
      }
      
      &:nth-of-type(n+4) {
        margin-top: 3.2rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    li {
      font-size: 2rem;
    }
    
    a {
      &:hover {
        color: ${colors.red600};
      }
    }
  }
`;

const IndustriesClients = styled.div`
  header {
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  li {
    padding: 0 1.6rem;
    flex: 0 0 50%;
    
    &:nth-of-type(n+3) {
      margin-top: 3.2rem;
    }
  }
  
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      & + * {
        margin-top: 6.4rem;
      }
    }
    
    li {
      padding: 0 4rem;
      flex-basis: 25%;
      
      &:nth-of-type(n+3) {
        margin-top: 0;
      }
      
      &:nth-of-type(n+5) {
        margin-top: 4rem;
      }
    }    
  }
`;

class HomepageIndustries extends Component {
  render() {
    const { heading, industries, clients } = this.props;

    return (
      <IndustriesContainer>
        <Inner>
          <header>
            <Wrap>
              <Signpost size='xsmall'>Industries We Serve</Signpost>

              {heading && <h2>{heading}</h2>}

              <IndustriesTrapdoor>
                <ArrowLink text='See Our Work' to='/work'/>
                <ArrowLink text='View Full Client List' to='/about/#all-clients'/>
              </IndustriesTrapdoor>
            </Wrap>
          </header>

          <IndustriesContent>
            {(industries && industries.length > 0) && (
              <ul>
                {industries.map((industry, i) => (
                  <li key={i}>
                    {industry.area && <Link to={`/about/?clients=${industry.area._meta.uid}#all-clients`}>{industry.area.name}</Link>}
                  </li>
                ))}
              </ul>
            )}
          </IndustriesContent>

          <IndustriesClients>
            <header>
              <Wrap>
                <h4>Select Clients</h4>
              </Wrap>
            </header>

            {(clients && clients.length > 0) && (
              <ul>
                {this.props.clients.map((client, i) => {
                  if (client.case_study) {
                    const study = client.case_study;

                    return (
                      <li key={i}>
                        <Fade bottom distance='1.6rem'>
                          <Link to={`/study/${study._meta.uid}`}>
                            <img src={study.logo.url} alt={study.logo.alt || study.client_name} />
                          </Link>
                        </Fade>
                      </li>
                    );
                  } else if (client.logo) {
                    return (
                      <li key={i}>
                        <Fade bottom distance='1.6rem'>
                          <img src={client.logo.url} alt={client.logo.alt || client.name || ''} />
                        </Fade>
                      </li>
                    );
                  }

                  return null;
                })}
              </ul>
            )}
          </IndustriesClients>
        </Inner>
      </IndustriesContainer>
    );
  }
}

export default HomepageIndustries;
