import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from "styles/structure";
import Signpost from "components/_ui/Signpost";
import ArrowLink from "components/_ui/ArrowLink";

const TeamContainer = styled.section`
  margin-top: 12.4rem;
  
  header {
    padding-bottom: 4.8rem;
  }
  
  a {
    margin-top: 1.6rem;
  }
  
  img {
    position: relative;
    display: block;
    width: 100%;
    height: 37.5rem;
    object-fit: cover;
    pointer-events: none;
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    margin-top: 16rem;
    
    header {
      padding-bottom: 8rem;
    }
  
    a {
      margin-top: 3.2rem;
    }
  }
`

class HomepageTeam extends Component {
  render() {
    return (
      <TeamContainer>
        <Inner>
          <Wrap>
            <header>
              <Signpost size="small">About Marino</Signpost>
              <h2>More than the sum of our parts.</h2>
              <ArrowLink text="Meet Marino" to="/about"/>
            </header>
          </Wrap>
        </Inner>

        <img src={this.props.image} alt="The Marino team" />
      </TeamContainer>
    );
  }
}

export default HomepageTeam;
